<template>
<div>
  <div class="top_order_info flex_row_start_center">
    <div class="top_logo">
      <div :style="`background-image:url(${configInfo.main_site_logo})`"></div>
    </div>

    <div class="top_info_progress flex_row_end_center">

      <div class="progress_item" v-if="pre_params.data.ifcart=='true'">
        <div class="progress flex_row_center_center">
          <span class="active">1</span>
          <div class="progress_line">
            <div :class="{content:true,active:true}"></div>
          </div>
        </div>
        <p>购物车</p>
      </div>

      <div class="progress_item">
        <div class="progress flex_row_center_center">
          <span class="active">{{pre_params.data.ifcart=='true'?2:1}}</span>
          <div class="progress_line">
            <div :class="{content:true,active:false,current:false}"></div>
          </div>
        </div>
        <p style="margin-left: -11px">确认订单</p>
      </div>

      <div class="progress_item">
        <div class="progress flex_row_center_center">
          <span :class="{active:false}">{{pre_params.data.ifcart=='true'?3:2}}</span>
          <div class="progress_line">
            <div :class="{content:true,active:false}"></div>
          </div>
        </div>
        <p>付款</p>
      </div>

      <div class="progress_item" style="margin-left: -24px">
        <div class="progress flex_row_end_center">
          <span :class="{active:false}">{{pre_params.data.ifcart=='true'?4:3}}</span>
        </div>
        <p>支付成功</p>
      </div>

    </div>
  </div>
  <div class="confirm_order_container">
    <!-- 收货信息模块隐藏 -->
    <!-- <div class="receive_info">
      <div class="receive_info_title">
        <span>收货信息</span>
      </div>
      <div v-if="orderAddress.data.memberName" class="receive_info_content flex_row_start_center">
        <div class="content_left flex_column_between_start">
          <span class="member">收货人：{{orderAddress.data.memberName}}</span>
          <span>联系方式：{{orderAddress.data.telMobile}}</span>
          <span :title="orderAddress.data.addressAll+orderAddress.data.detailAddress"
            class="address_detail overflow_ellipsis">收货地址：{{orderAddress.data.addressAll}}
            {{orderAddress.data.detailAddress}}</span>
        </div>
        <div class="content_right flex_column_center_center">
          <div class="replace_address pointer" @click="changeAddress">更换地址</div>
          <div class="add_address pointer" @click="addAddress">新建地址</div>
        </div>
      </div>
      <div v-else class="receive_info_content flex_row_center_center">
        <div class="add_address nomartop pointer" @click="addAddress">新建地址</div>
      </div>
    </div> -->
    <div v-for="(storeItem,index) in goods_data.data" :key="index" class="store_item">
      <div class="store_name">
        <span>{{storeItem.storeName}}</span>
      </div>
      <div class="good_container">
        <div v-for="(goodItem,index) in storeItem.productList" :key="index" class="good_item flex_row_start_center">
          <div class="good_info flex_row_start_center">
            <img class="good_image" :src="goodItem.image" alt />
            <div class="good_info_text">
              <div class="good_name overflow_ellipsis_two">{{goodItem.goodsName}}</div>
              <div class="good_spec overflow_ellipsis">{{goodItem.specValues}}</div>
            </div>
          </div>
          <div class="good_price">¥{{goodItem.price}}</div>
          <!-- <div class="num">*{{goodItem.buyNum}}</div> -->
        </div>
        <div v-for="(sendItem,index) in storeItem.sendProductList" :key="index" class="good_item flex_row_start_center">
          <div class="good_info flex_row_start_center">
            <img class="good_image" :src="sendItem.goodsImage" alt />
            <div class="good_info_text">
              <div class="good_name overflow_ellipsis_two"><span class="giftProduct">赠品</span>{{sendItem.goodsName}}
              </div>
              <div class="good_spec overflow_ellipsis">赠完为止</div>
            </div>
          </div>
          <div class="good_price"></div>
          <div class="num">*{{sendItem.num}}</div>
        </div>
      </div>
      <div class="store_coupon">
        <div class="store_coupon_title">
          <span>优惠券使用（{{storeItem.availableCouponList.length}}）</span>
        </div>
        <div class="store_coupon_con flex_row_start_center">
          <span class="title" v-if="storeItem.availableCouponList.length==0">暂无可用优惠券</span>
          <el-select class="voice_select" v-else v-model="couponCode" @change="changeCoupon(storeItem)"
            placeholder="请选择">
            <el-option value="不使用优惠券">不使用优惠券</el-option>
            <el-option v-for="(couponItem,index) in storeItem.availableCouponList" :key="index"
              :label="couponItem.couponName" :value="couponItem.couponCode"></el-option>
          </el-select>
        </div>
      </div>
      <div class="store_coupon invoice" v-if="pre_params.data.ifcart=='false'">
        <div class="store_coupon_title">
          <span>发票</span>
        </div>
        <div class="invoice_con flex">
          <div class="no_select_invoice flex_row_center_center">
            <img :src="not_select" v-if="select_invoice_info.invoiceId==''" alt @click="showVoiceDialog" />
            <img v-else :src="selected" alt />
            <span>我要开发票</span>
            <div class="invoice_info" v-if="select_invoice_info.invoiceId">
              <span class="invoice_name">{{select_invoice_info.invoiceTitle}}</span>
              <span class="invoice_type">{{select_invoice_info.invoiceContent}}</span>
              <span @click="showVoiceDialog" class="choose">重新选择</span>
            </div>
          </div>
        </div>
      </div>
      <div class="store_coupon">
        <div class="store_coupon_title">
          <span>订单备注</span>
        </div>
        <div class="store_coupon_con flex_row_start_center">
          <el-input maxlength="100" v-model="storeItem.remark" type="text" placeholder="给商家留言" show-word-limit />
        </div>
      </div>
      <div class="settle_info">
        <div class="total_money">
          <span>商品合计：</span>
          <span>¥{{storeItem.goodsAmount}}</span>
        </div>
        <div class="discount">
          <span>优惠：</span>
          <span class="red">-￥{{storeItem.totalDiscount}}</span>
        </div>
        <!-- <div class="shipping">
          <span>运费：</span>
          <span class="red">+￥{{storeItem.expressFee}}</span>
        </div> -->
      </div>
    </div>
    <div class="receive_info platform_discount">
      <div class="receive_info_title">
        <span>平台优惠券</span>
      </div>
      <div class="platform_discount_con flex_row_start_center">
        <span class="title" v-if="order_settle_info.platformCouponList.length==0">暂无可用优惠券</span>
        <el-select class="voice_select" v-else v-model="platformCouponCode" @change="confirmOrder(2)" placeholder="请选择">
          <el-option value="不使用优惠券">不使用优惠券</el-option>
          <el-option v-for="(couponItem,index) in order_settle_info.platformCouponList" :key="index"
            :label="couponItem.couponName" :value="couponItem.couponCode"></el-option>

        </el-select>
      </div>
    </div>
    <div class="receive_info platform_discount" v-if="pre_params.data.ifcart=='true'">
      <div class="receive_info_title">
        <span>发票</span>
      </div>
      <div class="invoice_con flex">
        <div class="no_select_invoice flex_row_center_center">
          <img :src="not_select" v-if="select_invoice_info.invoiceId==''" alt @click="showVoiceDialog" />
          <img :src="selected" v-else alt />
          <span>我要开发票</span>
          <div class="invoice_info" v-if="select_invoice_info.invoiceId">
            <span class="invoice_name">{{select_invoice_info.invoiceTitle}}</span>
            <span class="invoice_type">{{select_invoice_info.invoiceContent}}</span>
            <span @click="showVoiceDialog" class="choose">重新选择</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order_total_settle">
      <div class="order_price">
        <span>实付款：</span>
        <!-- <span>实付款（含运费）：</span> -->
        <span class="order_price_num">¥ {{order_settle_info.totalAmount}}</span>
      </div>
      <div class="order_discount">
        <span>优惠了：</span>
        <span class="red">¥ {{order_settle_info.totalDiscount}}</span>
      </div>
      <div class="goBuy" @click="checkOrder" style="cursor: pointer;">提交订单</div>
    </div>
    <!-- 发票选择——编辑 start -->
    <el-dialog title="发票信息" v-model="edit_voice_dialog" customClass="voice_width" :before-close="handleClose"
      lock-scroll="false">
      <p v-if="!add_voice_content" class="voice_list_title">发票历史信息选择：</p>
      <div v-if="!add_voice_content" class="voice_list">
        <div v-for="(voice,index) in invoice_list.data" :key="index" :title="voice.invoiceTitle"
          :class="{voice_item:true, active:voice.invoiceId==select_invoice_info.invoiceId,overflow_ellipsis:true,pointer:true}"
          @click="chooseVoice(voice)">
          <span>{{voice.invoiceTitle}}</span> <span v-if="voice.isDefault">默认</span>
          <img v-if="voice.invoiceId==select_invoice_info.invoiceId" :src="invoice_selected" alt />
        </div>
        <div class="add_voice_btn" @click="showAddvoice">新增发票</div>
        <div class="add_voice_btn add_voice_btn2" @click="removeAddvoice">暂不开发票</div>
      </div>
      <div class="add_voice_content" v-if="add_voice_content">
        <div class="item flex">
          <div class="title">发票内容：</div>
          <div class="right_info flex_column_center_start">
            <div class="item_select flex">
              <div @click="changeInvoiceContent(1)"
                :class="{select_btn:true,pointer:true, active:invoice_info.data.invoiceContent==1}">
                商品明细
                <img v-if="invoice_info.data.invoiceContent==1" :src="invoice_selected" alt />
              </div>
              <div @click="changeInvoiceContent(2)"
                :class="{select_btn:true,pointer:true, active:invoice_info.data.invoiceContent==2}">
                商品类别
                <img v-if="invoice_info.data.invoiceContent==2" :src="invoice_selected" alt />
              </div>
            </div>
            <p>发票内容将显示商品名称与价格信息，发票金额为实际支付金额，不含优惠等扣减金额</p>
          </div>
        </div>
        <!-- 发票抬头 -->
        <div class="item flex">
          <div class="title">发票抬头：</div>
          <div class="right_info flex_column_center_start">
            <div class="item_select flex">
              <div @click="changeInvoiceTitleType(1)"
                :class="{select_btn:true,pointer:true, active:invoice_info.data.titleType==1}">
                个人
                <img v-if="invoice_info.data.titleType==1" :src="invoice_selected" alt />
              </div>
              <div @click="changeInvoiceTitleType(2)"
                :class="{select_btn:true,pointer:true, active:invoice_info.data.titleType==2}">
                公司
                <img v-if="invoice_info.data.titleType==2" :src="invoice_selected" alt />
              </div>
            </div>
            <el-input v-if="invoice_info.data.titleType==1" class="input" size="small" placeholder="请在此填写发票抬头"
              v-model="invoice_info.data.invoiceTitle" clearable maxlength="30"></el-input>
          </div>
        </div>
        <!-- 发票类型： -->
        <div class="item flex" v-if="invoice_info.data.titleType==2">
          <div class="title">发票类型：</div>
          <div class="right_info flex_column_center_start">
            <div class="item_select flex">
              <div @click="changeInvoiceType(1)"
                :class="{select_btn:true,pointer:true, active:invoice_info.data.invoiceType==1}">
                普通发票
                <img v-if="invoice_info.data.invoiceType==1" :src="invoice_selected" alt />
              </div>
              <div @click="changeInvoiceType(2)"
                :class="{select_btn:true,pointer:true, active:invoice_info.data.invoiceType==2}">
                增值税专用发票
                <img v-if="invoice_info.data.invoiceType==2" :src="invoice_selected" alt />
              </div>
            </div>
            <!-- <el-input class="input" size="small" placeholder="请在此填写发票抬头" v-model="input" clearable></el-input> -->
          </div>
        </div>
        <!-- 单位名称： -->
        <div class="item flex" v-if="invoice_info.data.titleType==2">
          <div class="title">单位名称：</div>
          <div class="right_info flex_column_center_start">
            <el-input class="input nomartop" size="small" placeholder="请在此填写单位名称"
              v-model="invoice_info.data.companyName" clearable maxlength="30"></el-input>
          </div>
        </div>
        <!-- 税号： -->
        <div class="item flex" v-if="invoice_info.data.titleType==2">
          <div class="title">税号：</div>
          <div class="right_info flex_column_center_start">
            <el-input class="input nomartop" size="small" placeholder="请在此填写纳税人识别号" v-model="invoice_info.data.taxCode"
              clearable maxlength="20"></el-input>
            <el-input class="input" size="small" v-if="invoice_info.data.invoiceType==2" placeholder="请输入注册地址"
              v-model="invoice_info.data.registerAddr" clearable maxlength="100"></el-input>
            <el-input class="input" size="small" v-if="invoice_info.data.invoiceType==2" placeholder="请输入注册电话"
              v-model="invoice_info.data.registerPhone" clearable maxlength="11"></el-input>
            <el-input class="input" size="small" v-if="invoice_info.data.invoiceType==2" placeholder="请输入开户银行"
              v-model="invoice_info.data.bankName" clearable maxlength="30"></el-input>
            <el-input class="input" size="small" v-if="invoice_info.data.invoiceType==2" placeholder="请输入银行账户"
              v-model="invoice_info.data.bankAccount" clearable maxlength="50"></el-input>
            <el-input class="input" size="small" v-if="invoice_info.data.invoiceType==2" placeholder="请输入收票人姓名"
              v-model="invoice_info.data.receiverName" clearable maxlength="5"></el-input>
            <el-input class="input" size="small" v-if="invoice_info.data.invoiceType==2" placeholder="请输入收票人电话"
              v-model="invoice_info.data.receiverMobile" clearable maxlength="11"></el-input>
            <el-input class="input" size="small" v-if="invoice_info.data.invoiceType==2" placeholder="请输入收票人地址"
              v-model="invoice_info.data.receiverAddress" clearable maxlength="100"></el-input>
          </div>
        </div>
        <!-- 收票邮箱： -->
        <div class="item flex" v-if="invoice_info.data.invoiceType!=2">
          <div class="title">收票邮箱：</div>
          <div class="right_info flex_column_center_start">
            <el-input class="input nomartop" size="small" placeholder="请输入收票邮箱"
              v-model="invoice_info.data.receiverEmail" clearable maxlength="100" show-word-limit
              @input="EmailValidate"></el-input>
          </div>
        </div>
        <div class="validateMessage">{{rules}}</div>
        <div class="flex_row_end_center is_defalut">
          <span>设为默认发票</span>
          <el-switch v-model="invoice_info.data.isDefault" active-color="#EC2116" inactive-color="#EEEEEE"></el-switch>
        </div>
        <div class="btn_con flex_row_center_center">
          <div class="return" @click="voiceReturn">返回</div>
          <div class="confirm_add_voice" @click="confirmAddVoice">确定</div>
        </div>
      </div>
    </el-dialog>
    <!-- 发票选择——编辑 end -->
    <!-- 商品无货缺货弹窗 start -->
    <el-dialog :title="no_good_info.data.stateValue" v-model="out_stock_dialog" customClass="out_store_width"
      :before-close="handleClose" lock-sroll="false">
      <div class="out_stock_dialog">
        <div v-for="(outStockItem,index) in no_good_info.data.productList" :key="index" class="good_item flex">
          <img :src="outStockItem.image" alt />
          <div class="good_info">
            <span class="good_name overflow_ellipsis">{{outStockItem.goodsName}}</span>
            <div class="spec_num">
              <span class="good_spec">{{outStockItem.specValues}}</span>
              <span class="good_num">*{{outStockItem.buyNum}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_con flex_row_center_center" v-if="no_good_info.data.state==2">
        <div class="return" @click="returnLastPage">返回</div>
        <div class="remove" @click="clearFailureGoods">移除无货商品</div>
      </div>
      <div class="btn_con flex_row_center_center" v-else>
        <div class="return red" @click="returnLastPage">返回</div>
      </div>
    </el-dialog>
    <!-- 商品无货缺货弹窗 end -->
    <!-- 地址选择弹窗 start -->
    <el-dialog title="更换地址" v-model="show_select_address" customClass="select_address_width" :before-close="handleClose"
      lock-sroll="false">
      <div class="out_stock_dialog address_con">
        <div v-for="(item,index) in address_list.data" :key="index" @click="selectAddress(index)"
          :class="{address_item:true, flex_column_start_start:true, select:current_address_index==index}">
          <span>收货人：{{item.memberName}}</span>
          <span>联系方式：{{item.telMobile}}</span>
          <div class="address_text" :title="item.addressAll+item.detailAddress">
            <span>收货地址：</span>
            <span class="overflow_ellipsis_clamp2">{{item.addressAll}} {{item.detailAddress}}</span>
          </div>
          <img v-if="current_address_index==index" class="selected" :src="invoice_selected" alt />
        </div>
      </div>
      <div class="btn_con flex_row_center_center">
        <div class="remove red" @click="confirmChangeAddress()">提交修改</div>
      </div>
    </el-dialog>
    <!-- 地址选择弹窗 end -->
    <SldAddressOperation v-if="addressVisibleFlag" @close="close" @refreshAddress="refreshAddress" :visibleFlag="true"
      :type="type" />
  </div>
</div>
  
</template>

<script>
  import { reactive, getCurrentInstance, ref, onMounted, onUnmounted } from "vue";
  import {
    ElDialog,
    ElInput,
    ElSwitch,
    ElMessage,
    ElSelect,
    ElOption
  } from "element-plus";
  import { useRoute, useRouter } from "vue-router";
  import SldAddressOperation from "../../components/AddressOperation";
  import { useStore } from 'vuex'
  export default {
    name: "ConfirmOrder",
    components: {
      ElDialog,
      ElInput,
      ElSwitch,
      ElSelect,
      ElOption,
      SldAddressOperation
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      const store = useStore()
      const addressVisibleFlag = ref(false);
      const { proxy } = getCurrentInstance();
      const not_select = require("../../assets/buy/not_select.png");
      const selected = require("../../assets/buy/selected.png");
      const invoice_selected = require("../../assets/buy/invoice_selected.png");
      const couponCode = ref("")
      const platformCouponCode = ref("")
      const navlist = reactive({ data: [] });
      const show_change_address = ref(false);
      const edit_voice_dialog = ref(false);
      const add_voice_content = ref(false);
      const out_stock_dialog = ref(false);
      const type = ref("add");
      const timer = ref(0); //定时器
      const no_good_info = reactive({ data: {} });
      const show_select_address = ref(false);
      const pre_params = reactive({ data: {} });
      const orderAddress = reactive({ data: "" });
      const address_list = reactive({ data: [] });
      const goods_data = reactive({ data: [] });
      const configInfo = ref(store.state.configInfo)
      const current_address_index = ref(0);
      const select_invoice_info = reactive({
        invoiceId: "",
        invoiceContent: "",
        invoiceTitle: ""
      });
      const selCouponStore = reactive({ data: {} })
      const rules = ref('')
      const invoice_list = reactive({ data: [] }); //发票列表
      const invoice_info = reactive({
        data: {
          //新增发票信息
          bankAccount: "", //银行帐户(专用发票）
          bankName: "", //开户银行(专用发票）
          invoiceContent: 1, //发票内容：1-商品明细；2-商品类别
          invoiceTitle: "", //发票抬头(通用信息）
          invoiceType: 1, //发票类型：1-普通发票；2-增值税发票
          isDefault: false, //是否默认发票：0-非默认发票，1-默认发票
          receiverAddress: "", //收票人详细地址(通用信息）
          receiverEmail: "", //	收票邮箱(通用信息）
          receiverMobile: "", //收票人手机号(通用信息）
          receiverName: "", //收票人姓名(通用信息）
          registerAddr: "", //注册地址(专用发票）
          registerPhone: "", //注册电话(专用发票）
          taxCode: "", //纳税人识别号(通用信息）
          titleType: 1, //抬头类型：1-个人；2-公司
          companyName: "" //单位名称
        }
      });
      // 重置发票信息
      const resetVoiceInfo = () => {
        invoice_info.data.bankAccount = "";
        invoice_info.data.bankName = "";
        invoice_info.data.invoiceContent = 1;
        invoice_info.data.invoiceTitle = "";
        invoice_info.data.invoiceType = 1;
        invoice_info.data.isDefault = false;
        invoice_info.data.receiverAddress = "";
        invoice_info.data.receiverEmail = "";
        invoice_info.data.receiverMobile = "";
        invoice_info.data.receiverName = "";
        invoice_info.data.registerAddr = "";
        invoice_info.data.registerPhone = "";
        invoice_info.data.taxCode = "";
        invoice_info.data.titleType = 1;
        invoice_info.data.companyName = "";
      };
      const order_settle_info = reactive({
        totalAmount: "",
        totalDiscount: "",
        platformCouponList: [],
        platformCouponCode: ""
      });

      const EmailValidate = () => {
        let result = proxy.$checkEmail(invoice_info.data.receiverEmail)
        if (result == true) {
          rules.value = ''
        } else {
          rules.value = result
        }
      }

      const changeCoupon = (storeItem) => {
        selCouponStore.data = {
          store: storeItem,
          couponCode: couponCode.value
        }
        confirmOrder(2)
      }

      //用于切换地址，使用优惠券，获取信息，运费等
      const confirmOrder = type => {
        let param = {};
        param.source = type;
        param.addressId = orderAddress.data.addressId;
        param.platformCouponCode = platformCouponCode.value == "不使用优惠券" ? "" : platformCouponCode.value;
        param.isAloneBuy = true
        let storeInfoList = [];
        // if (goods_data.data.length) {
        goods_data.data.map(item => {
          let storeitem = {};
          if (selCouponStore.data.store && (selCouponStore.data.store.storeId == item.storeId)) {
            storeitem.remark = selCouponStore.data.store.remark;
            storeitem.storeCouponCode = selCouponStore.data.couponCode == "不使用优惠券" ? "" : selCouponStore.data.couponCode;
            storeitem.storeId = item.storeId;
          } else {
            storeitem.remark = item.remark;
            storeitem.storeCouponCode = item.couponCode
            storeitem.storeId = item.storeId;
          }
          storeInfoList.push(storeitem);
        });

        param.storeInfoList = storeInfoList;
        if (pre_params.data.ifcart == "true") {
          //来自于购物车
          param.isCart = true;
        } else {
          //立即购买
          param.productId = pre_params.data.productId;
          param.number = pre_params.data.numbers;
          param.isCart = false;
        }
        proxy
          .$post(
            "v3/business/front/orderOperate/confirm",
            param,
            "application/json"
          )
          .then(res => {
            if (res.state == 200) {
              goods_data.data = res.data.storeGroupList;
              console.log(goods_data.data)
              goods_data.data.map(item => {
                item.remark = "";
                let default_coupon = item.availableCouponList.filter(function (
                  coupon
                ) {
                  return coupon.checked == true;
                });
                if (default_coupon.length > 0) {
                  item.couponCode = default_coupon[0].couponCode;
                } else {
                  item.couponCode = "";
                }
              });
              order_settle_info.totalAmount = res.data.totalAmount;
              order_settle_info.platformCouponList = res.data.availableCouponList;
              order_settle_info.totalDiscount = res.data.totalDiscount;

              //处理平台优惠券
              let default_plat_coupon = order_settle_info.platformCouponList.filter(
                function (coupon) {
                  return coupon.checked == true;
                }
              );
              if (default_plat_coupon.length > 0) {
                order_settle_info.platformCouponCode =
                  default_plat_coupon[0].couponCode;
              } else {
                order_settle_info.platformCouponCode = "";
              }
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //获取地址列表
      const getAddressList = () => {
        proxy
          .$get("v3/member/front/memberAddress/list")
          .then(res => {
            if (res.state == 200) {
              address_list.data = res.data.list;
              if (res.data.list.length > 0 && !orderAddress.data.addressId) {
                var default_address = res.data.list.filter(function (item) {
                  return item.isDefault == 1;
                });
                if (default_address.length > 0) {
                  orderAddress.data = default_address[0];
                } else {
                  orderAddress.data = res.data.list[0];
                }
                if (localStorage.getItem('addressId')) {
                  let addressID = localStorage.getItem('addressId')
                  if (res.data.list.filter(i => i.addressId == addressID)[0]) {
                    orderAddress.data = res.data.list.filter(i => i.addressId == addressID)[0]

                  }
                }
              }

              confirmOrder(2);
            } else {
              confirmOrder(2);
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //切换地址
      const selectAddress = index => {
        current_address_index.value = index;
      };
      //确认修改地址
      const confirmChangeAddress = () => {
        orderAddress.data = address_list.data[current_address_index.value];
        show_select_address.value = false;
        confirmOrder(2);
        localStorage.setItem('addressId', orderAddress.data.addressId)
      };
      //弹出地址选择框
      const changeAddress = () => {
        getAddressList();
        show_select_address.value = true;
      };
      //弹出发票选择框
      const showVoiceDialog = () => {
        edit_voice_dialog.value = true;
        getVoiceList();
      };
      const showAddvoice = () => {
        add_voice_content.value = true;
      };
      //获取发票列表
      const getVoiceList = () => {
        proxy
          .$get("v3/member/front/memberInvoice/list")
          .then(res => {
            if (res.state == 200) {
              invoice_list.data = res.data.list;
              if (res.data.list.length > 0) {
                add_voice_content.value = false;
              } else {
                add_voice_content.value = true;
              }
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      const removeAddvoice = () => {
        select_invoice_info.invoiceId = false;
        edit_voice_dialog.value = false;
      }
      //切换发票内容
      const changeInvoiceContent = invoiceContent => {
        invoice_info.data.invoiceContent = invoiceContent;
      };
      //切换发票抬头
      const changeInvoiceTitleType = titleType => {
        invoice_info.data.titleType = titleType;
      };
      //切换发票类型
      const changeInvoiceType = invoiceType => {
        invoice_info.data.invoiceType = invoiceType;
      };
      //新增发票
      const confirmAddVoice = () => {
        if (invoice_info.data.titleType == 1) {
          if (invoice_info.data.invoiceTitle == "") {
            ElMessage("请填写发票抬头");
            return;
          }
          var reg = new RegExp(
            "^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$"
          );
          if (invoice_info.data.receiverEmail == "") {
            ElMessage("请填写收票邮箱");
            return;
          }
          if (!reg.test(invoice_info.data.receiverEmail)) {
            ElMessage("邮箱格式不正确，请重新输入！");
            return;
          }
        } else if (invoice_info.data.titleType == 2) {
          if (invoice_info.data.companyName == "") {
            ElMessage("请填写单位名称");
            return;
          }
          if (invoice_info.data.taxCode == "") {
            ElMessage("请填写纳税人税号");
            return;
          }
          let regExp = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/;
          if (regExp.test(invoice_info.data.taxCode) == false) {
            ElMessage("请填写正确的税号");
            return;
          }
          if (invoice_info.data.invoiceType == 1) {
            if (invoice_info.data.receiverEmail == "") {
              ElMessage("请填写收票邮箱");
              return;
            }
          } else {
            if (invoice_info.data.registerAddr == "") {
              ElMessage("请填写注册地址");
              return;
            }
            if (invoice_info.data.registerPhone == "") {
              ElMessage("请填写注册电话");
              return;
            }
            if (invoice_info.data.bankName == "") {
              ElMessage("请填写开户银行");
              return;
            }
            if (invoice_info.data.bankAccount == "") {
              ElMessage("请填写银行账户");
              return;
            }
            if (invoice_info.data.receiverName == "") {
              ElMessage("请填写收票人姓名");
              return;
            }
            if (invoice_info.data.receiverMobile == "") {
              ElMessage("请填写收票人电话");
              return;
            }
            if (invoice_info.data.receiverAddress == "") {
              ElMessage("请填写收票人地址");
              return;
            }
          }
        }
        var param = {};
        param.titleType = invoice_info.data.titleType;
        param.invoiceType = invoice_info.data.invoiceType;
        param.invoiceContent = invoice_info.data.invoiceContent;
        if (invoice_info.data.isDefault) {
          param.isDefault = 1;
        } else {
          param.isDefault = 0;
        }
        if (invoice_info.data.invoiceType != 2) {
          param.receiverEmail = invoice_info.data.receiverEmail;
        }
        if (invoice_info.data.titleType == 1) {
          param.invoiceTitle = invoice_info.data.invoiceTitle;
        } else {
          param.taxCode = invoice_info.data.taxCode;
          param.invoiceTitle = invoice_info.data.companyName;
          if (invoice_info.data.invoiceType == 2) {
            param.registerAddr = invoice_info.data.registerAddr;
            param.registerPhone = invoice_info.data.registerPhone;
            param.bankName = invoice_info.data.bankName;
            param.bankAccount = invoice_info.data.bankAccount;
            param.receiverName = invoice_info.data.receiverName;
            param.receiverMobile = invoice_info.data.receiverMobile;
            param.receiverAddress = invoice_info.data.receiverAddress;
          }
        }
        proxy
          .$post("v3/member/front/memberInvoice/add", param)
          .then(res => {
            if (res.state == 200) {
              if (res.state == 200) {
                select_invoice_info.invoiceTitle = invoice_info.data.titleType == 2 ? invoice_info.data.companyName : invoice_info.data.invoiceTitle;
                select_invoice_info.invoiceContent =
                  invoice_info.data.invoiceContent == 1 ? "商品明细" : "商品类别";
                select_invoice_info.invoiceId = res.data;
                edit_voice_dialog.value = false;
                add_voice_content.value = false;
                resetVoiceInfo();
                ElMessage.success({
                  message: "新增成功",
                  type: "success"
                });
              }
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //新增发票返回
      const voiceReturn = () => {
        if (invoice_list.data.length > 0) {
          add_voice_content.value = false;
        } else {
          edit_voice_dialog.value = false;
        }
        resetVoiceInfo();
      };
      //选择发票
      const chooseVoice = voice => {
        select_invoice_info.invoiceTitle = voice.invoiceTitle;
        select_invoice_info.invoiceContent =
          voice.invoiceContent == 1 ? "商品明细" : "商品类别";
        select_invoice_info.invoiceId = voice.invoiceId;
        edit_voice_dialog.value = false;
      };
      //确认订单前校验商品是否可购买
      const checkOrder = () => {
    
        let param = {};
        if (pre_params.data.ifcart == "true") {
          param.isCart = true;
        } else {
          param.isCart = false;
          param.productId = pre_params.data.productId;
          param.number = pre_params.data.numbers;
        }
        proxy
          .$post(
            "v3/business/front/orderOperate/check",
            param,
            "application/json"
          )
          .then(res => {
            if (res.state == 267) {
              no_good_info.data = res.data;
              out_stock_dialog.value = true;
            } else if (res.state == 200) {
              goBuy();
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //返回上一页‘
      const returnLastPage = () => {
        out_stock_dialog.value = false;
        router.go(-1);
      };
      //移除无货商品
      const clearFailureGoods = () => {
        proxy
          .$post("v3/business/front/cart/emptyInvalid")
          .then(res => {
            if (res.state == 200) {
              out_stock_dialog.value = false;
              confirmOrder(2);
              ElMessage.success("移除成功");
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //跳转订单页
      const goBuy = () => {
        let param = {};
        param.source = 3;
        param.orderFrom = 1;
        param.addressId = orderAddress.data.addressId;
        param.platformCouponCode = order_settle_info.platformCouponCode;
        param.isAloneBuy = true
        let storeInfoList = [];
        goods_data.data.map(item => {
          let storeitem = {};
          storeitem.invoiceId = select_invoice_info.invoiceId;
          storeitem.remark = item.remark;
          storeitem.storeCouponCode = item.couponCode;
          storeitem.storeId = item.storeId;
          storeInfoList.push(storeitem);
        });
        param.storeInfoList = storeInfoList;
        if (pre_params.data.ifcart == "true") {
          //来自于购物车
          param.isCart = true;
        } else { //立即购买
          param.productId = pre_params.data.productId;
          param.number = pre_params.data.numbers;
          param.isCart = false;
        }

        proxy .$post("/v3/business/front/orderOperate/submit",
            param,
            "application/json"
          )
          .then(res => {
            if (res.state == 200) {
              let paySn = res.data.paySn;
              timer.value = setInterval(() => {
                queryPayState(paySn);
              }, 100);
              router.push({
                path: '/member/order/list',
                query: { 'freshPage':true }
              })
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      // 定时查询是否可以支付
      const queryPayState = paySn => {
        getPayInfo(paySn);
      };
      //获取订单支付数据
      const getPayInfo = () => {
        // proxy
        //   .$get("v3/business/front/orderPay/payInfo", {
        //     paySn: paySn,
        //     payFrom: 1
        //   })
        //   .then(res => {
        //     if (res.state == 200) {
        //       if (res.data.dealState == 3) {
        //         router.push({
        //           path: "Pay",
        //           query: {
        //             paySn: paySn,
        //             payFrom: 1,
        //             ifcart: pre_params.data.ifcart
        //           }
        //         });
        //         //清除定时器
        //         if (timer.value) {
        //           clearInterval(timer.value);
        //           timer.value = 0;
        //         }
        //       } else if (res.data.dealState == 2) {
        //         ElMessage("提交订单失败，请稍后重试");
        //         //清除定时器
        //         if (timer.value) {
        //           clearInterval(timer.value);
        //           timer.value = 0;
        //         }
        //       }
        //     } else if (res.state == 267) {
        //       ElMessage.success(res.msg + ",2s后自动跳转订单列表");
        //       //清除定时器
        //       if (timer.value) {
        //         clearInterval(timer.value);
        //         timer.value = 0;
        //       }
        //       setTimeout(() => {
        //         router.replace({
        //           path: "/member/order/list"
        //         });
        //       }, 2000);
        //     } else {
        //       //清除定时器
        //       if (timer.value) {
        //         clearInterval(timer.value);
        //         timer.value = 0;
        //       }
        //       ElMessage(res.msg);
        //     }
        //   })
        //   .catch(() => {
        //     //异常处理
        //   });
      };
      onUnmounted(() => {
        if (timer.value) {
          clearInterval(timer.value);
          timer.value = 0;
        }
      });
      //关闭弹窗
      const close = () => {
        addressVisibleFlag.value = false;
      };
      //弹出新建地址窗口
      const addAddress = () => {
        console.log(111);
        addressVisibleFlag.value = true;
      };
      const refreshAddress = (addressInfo, addressId) => {
        orderAddress.data = addressInfo;
        orderAddress.data.addressId = addressId;
      };
      onMounted(() => {
        pre_params.data = route.query;
        getAddressList();
      });
      return {
        navlist,
        not_select,
        selected,
        show_change_address,
        edit_voice_dialog,
        showVoiceDialog,
        invoice_selected,
        add_voice_content,
        showAddvoice,
        removeAddvoice,
        out_stock_dialog,
        orderAddress,
        changeAddress,
        show_select_address,
        address_list,
        current_address_index,
        selectAddress,
        confirmChangeAddress,
        goods_data,
        pre_params,
        invoice_info,
        changeInvoiceContent,
        changeInvoiceTitleType,
        changeInvoiceType,
        confirmAddVoice,
        invoice_list,
        select_invoice_info,
        voiceReturn,
        chooseVoice,
        order_settle_info,
        confirmOrder,
        checkOrder,
        goBuy,
        returnLastPage,
        clearFailureGoods,
        no_good_info,
        addressVisibleFlag,
        close,
        type,
        refreshAddress,
        addAddress,
        couponCode,
        platformCouponCode,
        configInfo,
        rules,
        EmailValidate,
        changeCoupon
      };
    }
  };
</script>

<style lang="scss" scoped>
  @import "../../style/confirmOrder.scss";
</style>
<style>
  .voice_width {
    width: 605px !important;
  }

  .add_voice_btn2 {
    margin: -30px 114px 10px !important;
  }

  .out_store_width {
    width: 480px !important;
  }

  .select_address_width {
    width: 500px !important;
  }

  .el-dialog__body {
    padding-top: 10px;
  }

  .el-input__inner {
    font-size: 12px;
  }

  .el-select .el-input__inner:focus {
    border-color: #e2231a;
  }

  .el-select .el-input.is-focus .el-input__inner {
    border-color: #e2231a;
  }

  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #e2231a;
    outline: 0;
  }

  .validateMessage {
    font-size: 12px;
    color: #e2231a;
    margin-top: 5px;
    padding-left: 80px;
    height: 12px;
  }

  .giftProduct {
    display: inline-block;
    border: 1px solid #e2231a;
    color: #e2231a;
    width: 33px;
    height: 16px;
    border-radius: 3px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-right: 4px;
  }
</style>